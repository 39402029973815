<template>
  <v-carousel
    id="hero"
    cycle
    hide-delimiter-background
    show-arrows-on-hover
    height="90vh"
  >
    <v-carousel-item
      v-for="(photo,j) in photos"
      :key="j"
      class="custom-parallax"
      :src="photo.src"
    >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-container class="justify-center text-center custom-width">
              <div
                v-vue-aos="{animationClass:'animate__slideInUp animate__animated'}"
                class="justify-center sec-font text-center choclate-color "
              >
                {{ photo.title }}
              </div>
              <br>

              <div
                v-vue-aos="{animationClass:'animate__slideInUp animate__animated'}"
                class="myfont justify-center ttext-h2 text-center"
              >
                {{ photo.subtitle }}
              </div>
              <v-btn
                v-vue-aos="{animationClass:'animate__slideInDown animate__animated'}"
                class="mt-10 white--text sec-font"
                color="#d8bf9a"
                tile
                height="40px"
                width="150px"
              >
                order now
              </v-btn>
            </v-container>
          </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>

export default {

  data () {
    return {
      photos: [
        {
          src: 'https://swissdelight.qodeinteractive.com/wp-content/uploads/2021/02/h1-rev-img2.jpg',
          subtitle: 'Indulge your senses in chocolate paradise.',
          title: 'ALMORA choclates'
        },
        {
          src: 'https://swissdelight.qodeinteractive.com/wp-content/uploads/2021/02/h1-rev-img1-2.jpg',
          subtitle: 'Discover the magic of chocolate.',
          title: 'ALMORA choclates'
        },
        {
          src: 'https://swissdelight.qodeinteractive.com/wp-content/uploads/2021/02/h1-rev-img3.jpg',
          subtitle: 'Experience chocolate perfection in every bite.',
          title: 'ALMORA choclates'
        }
      ]

    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ysabeau:ital,wght@1,300&display=swap');
$font-family: 'Dancing Script', serif;
.choclate-color {
  color: #c09e76;
}
.choclate-color-1 {
  color: #d8bf9a;
}
.custom-parallax {
  height: 100vh;
}
.myfont{
  font-family: $font-family, sans-serif !important;
  font-size: 1.75rem ;
}
.ttext-h2{
  font-size: 5rem !important;
    font-weight: 300;
    line-height: 3.75rem;
    letter-spacing: -0.0083333333em !important;
}
.ttext-h6{
  font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
}
.custom-width{
  width: 700px;
}
.sec-font{
  font-family: 'Ysabeau' !important;
}

</style>
