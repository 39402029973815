<template>
  <div class="background-component">
    <v-row class="d-flex justify-space-between mt-16">
      <v-col class="mt-16 ml-16  ">
        <div class="ttext-h3 my-5">
          Our patisserie produces
          <span class="uniqe choclate-color">unique sweets</span> for lovers
          of yummy
        </div>
        <div class="subtitle-3 my-5 mt-10">
          From the first melt-in-your-mouth sensation to the lingering satisfaction, our chocolates are an unforgettable experience
        </div>
        <v-row class="d-flex align-center mt-10">
          <img class="mx-4" width="32"  src="../assets/icons8-photo-96.png" alt="stack-of-photos"/>
          <div class="text--secondary"> see all</div>
      </v-row>
      <div class="mt-10 d-flex align-center colory">
       READ MORE
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#b48e66">
       <path d="M9 18l6-6-6-6" />
        </svg>
      </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="mt-16 mr-16 ">
        <v-row class="d-flex align-center my-5">
          <v-col class="col-3">
            <img src="../assets/icons8-chocolate-100.png" width="80" alt="">
          </v-col>
          <v-col class="col-9">
            <div class="Ysabeau">Natural Organic Product</div>
            <div class="text--secondary text-body-1">Vivamus vel magna non mi gravida ultr sed ut turpis.</div>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center my-5">
          <v-col class="col-3">
            <img src="../assets/icons8-chocolate-64.png" width="80" alt="">
          </v-col>
          <v-col class="col-9">
            <div  class="Ysabeau">Packaging Design</div>
            <div class="text--secondary Ysabeau text-body-1">Vivamus vel magna non mi gravida ultr sed ut turpis.</div>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center my-5">
          <v-col class="col-3">
            <img src="../assets/icons8-cocoa-64.png" width="80" alt="">
          </v-col>
          <v-col class="col-9">
            <div  class="Ysabeau">Best Quality Cocoa</div>
            <div class="text--secondary text-body-1">Vivamus vel magna non mi gravida ultr sed ut turpis.</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.background-component {
  background-image: url('../assets/chocolate-bar-with-cinnamon-sticks.jpg'); /* Replace with the path to your image */
  background-size: contain;
  background-position: center;
  height: 100vh;
  /* Add additional styles as needed */
}
.ttext-h3{
  font-size: 3rem !important;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
    font-family: 'Ysabeau' !important;
}
.uniqe{
  font-family: 'Dancing Script' !important;
}
.uniqe-size{
  font-family: 'Dancing Script' !important;
  font-size: 1.25rem ;
}
.subtitle-3{
  font-size: 0.875rem !important;
    font-weight: 900;
    letter-spacing: 0.0071428571em !important;
    line-height: 1.375rem;
    color:#362718 ;
    font-family: "Ysabeau", sans-serif !important;
}
.colory{
  color: #b48e66;
}
.Ysabeau{
  font-family: 'Ysabeau' !important;
  font-size: 1.75rem ;
    font-weight: 400;
    line-height: 3.125rem;
    letter-spacing: normal !important;
}
</style>
