<template>
  <div>
    <hello-world/>
    <FeatureSec/>
    <description-sec/>
    <parallax-img/>
    <qoutes-sec/>
  </div>
</template>

<script>
import FeatureSec from '@/components/FeatureSec.vue'
import DescriptionSec from '@/components/DescriptionSec.vue'
import ParallaxImg from '@/components/ParallaxImg.vue'
import HelloWorld from '@/components/HelloWorld.vue'
import qoutesSec from '@/components/qoutesSec.vue'

export default {
  name: 'HomePage',
  components: {
    FeatureSec,
    DescriptionSec,
    ParallaxImg,
    qoutesSec,
    HelloWorld
  }
}
</script>
