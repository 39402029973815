<template>
  <v-app>
    <v-app-bar
      app
      class="gradient"
      elevation="0"
      style="z-index: 1000000;"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="130"
          src="@/assets/Untitled_logo.png"
          width="130"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
         :to="{ name: 'HomePage' }"
        text
      >
        <span class="mr-2">Home</span>
      </v-btn>
      <v-btn
         :to="{ name: 'about' }"
        text
      >
        <span class="mr-2">About</span>
      </v-btn>
      <v-btn
         :to="{ name: 'gallery' }"
        text
      >
        <span class="mr-2">Gallery</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
    <v-footer
      cols="12"
      md="3"
      padless
      height="450"
      class=" d-flex align-end"
      style="background-color: #FCF8ED;"
    >
    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="svg-div-2">
  <path fill="#d8bf9a" fill-opacity="1" d="M0,224L48,208C96,192,192,160,288,133.3C384,107,480,85,576,96C672,107,768,149,864,176C960,203,1056,213,1152,186.7C1248,160,1344,96,1392,64L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
</svg> -->
      <v-container >
        <v-row
          justify="center"
          class="d-flex justify-space-around text-center"
        >
          <v-col
            cols="12"
            md="4"
            class="pa-0"
          >
            <div
              v-vue-aos="{animationClass:'animate__fadeInRight animate__animated'}"
            >
              <img   src="@/assets/Untitled_logo1.png" alt="" width="150" >
            </div>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pa-0"
          >
            <div
              v-vue-aos="{animationClass:'animate__fadeInUp animate__animated'}"
            >
              <div class=" font-weight-bold Ysabeau text-left ">
                popular news
              </div>
              <v-list-item>
                <v-list-item-title class="text-left text--secondary text-body-1">
                Subscribe to get special offers, <br> free gifts and once-in-a-lifetime deals.
              </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="text-left text--secondary text-body-1">
                Subscribe to get special offers, <br> free gifts and once-in-a-lifetime deals.
              </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="text-left text--secondary text-body-1">
                Subscribe to get special offers, <br> free gifts and once-in-a-lifetime deals.
              </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="text-left text--secondary text-body-1">May 04,2023</v-list-item-title>
              </v-list-item>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pa-0"
          >
            <div
              v-vue-aos="{animationClass:'animate__fadeInLeft animate__animated'}"
            >
            <div class=" font-weight-bold Ysabeau">
              Useful Links
              </div>
              <v-row>
                <v-col>
                  <v-list-item>
                    <v-list-item-title>
                      <a
                        class="text-left text--secondary text-body-1 "
                        href="/gallery"
                      >
                        Gallery</a>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>
                      <a
                        class="text-left text--secondary text-body-1 "
                       href="/about"
                      >
                        About</a>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>
                      <a
                        class="text-left text--secondary text-body-1 "
                       href="/"
                      >
                        Home</a>
                    </v-list-item-title>
                  </v-list-item>
                  <v-hover v-slot="{hover}">
                    <v-btn
                      class="sec-font font-weight-bold"
                      :outlined="!hover"
                      color="#b48e66"
                      :to="{name: 'Reservation'}"
                    >
                     Order now
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-card
        flat
        tile
        class=" white--text "
        width="100%"
        min-height="20%"
        style=" background-color: #d8bf9a;"
      >
        <v-row
          class="text-center pt-8"
          style=" color:#757575"
        >
          <v-col
            cols="12"
            md="3"
          >
            &copy; {{ new Date().getFullYear() }} — <strong>sdsd. All rights reserved</strong>
          </v-col>
          <v-col>
            <v-btn
              v-for="icon in icons"
              :key="icon"
              style=" color:#757575"
              icon
              cols="12"
              md="3"
            >
              <v-icon size="18px">
         sdsds
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="3"
            style=" color:#757575"
          >
            <strong> privacy policy </strong>
          </v-col>
        </v-row>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>

<style>
.gradient{
  background:linear-gradient(45deg, #d8bf9a, #ccae88, #c09e76, #b48e66);
}
</style>
