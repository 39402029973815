<template>
  <v-carousel
    id="hero"
    cycle
    vertical
    vertical-delimiters="true"
    dark
    height="70vh"
  >
  <img src="https://swissdelight.qodeinteractive.com/wp-content/uploads/2021/04/h1-offset-image.png" style="position: absolute; right: 300px; top: 50px;" alt="">

    <v-carousel-item
      v-for="(photo,j) in photos"
      :key="j"
      class="custom-parallax"
    >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-container class="justify-center text-center custom-width">
              <div
                v-vue-aos="{animationClass:'animate__slideInUp animate__animated'}"
                class="justify-center sec-font text-center bigger-size"
              >
               "
              </div>
              <div
                v-vue-aos="{animationClass:'animate__slideInUp animate__animated'}"
                class="justify-center sec-font text-center choclate-color bigger-size"
              >
                {{ photo.title }}
              </div>
              <br>
             <v-row class="d-flex align-center justify-center">
              <v-col class="col-1">
                <v-avatar color="#d8bf9a">
                    <v-icon dark>
                      mdi-account-circle
                    </v-icon>
                  </v-avatar>
                </v-col>
              <v-col class="col-2">
                <h4 class="mx-2 text--secondary">John Doe</h4>
                <h6 class="mx-2 text--secondary sec-font">John Doe</h6>
                </v-col>
             </v-row>
            </v-container>
          </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>

export default {
  data () {
    return {
      photos: [
        {
          src: 'primary',
          subtitle: 'Indulge your senses in chocolate paradise.',
          title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio inventore earum id tenetur? Possimus fuga totam quam nesciunt impedit ipsam, a quos eligendi veritatis incidunt alias suscipit hic eaque natus.'
        },
        {
          src: 'primary',
          subtitle: 'Discover the magic of chocolate.',
          title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio inventore earum id tenetur? Possimus fuga totam quam nesciunt impedit ipsam, a quos eligendi veritatis incidunt alias suscipit hic eaque natus.'
        },
        {
          src: 'primary',
          subtitle: 'Experience chocolate perfection in every bite.',
          title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odio inventore earum id tenetur? Possimus fuga totam quam nesciunt impedit ipsam, a quos eligendi veritatis incidunt alias suscipit hic eaque natus.'
        }
      ]

    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ysabeau:ital,wght@1,300&display=swap');
$font-family: 'Dancing Script', serif;
.bigger-size{
  font-size: 1.75rem ;
}
.v-carousel__controls{
  background: #d3c0a4c0;
}
</style>
