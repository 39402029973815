<template>
  <div class="background-color">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="svg-div-1">
    <path fill="#FCF8ED" fill-opacity="1" d="M0,224L48,208C96,192,192,160,288,133.3C384,107,480,85,576,96C672,107,768,149,864,176C960,203,1056,213,1152,186.7C1248,160,1344,96,1392,64L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
  </svg>
  <v-row class="d-flex align-center justify-center">
  <img src="../assets/Untitled_logo1.png" width="150px" class="mt-16" alt="" style="z-index: 2;">
  </v-row>
  <v-row class="d-flex align-center justify-center">
  <v-col class="col-4 ml-16 mb-5">
    <h6 class="Ysabeau" style=" font-size: 1rem;  line-height: 2rem;">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, quibusdam hic accusamus sint mollitia soluta? Sed unde provident explicabo laboriosam numquam repellat itaque doloribus. Accusamus eum necessitatibus consectetur reiciendis aliquid!</h6>
  </v-col>
  <v-col class="col-4 ml-16 mb-5">
    <img src="https://swissdelight.qodeinteractive.com/wp-content/uploads/2021/04/h1-offset-image.png" style="position: absolute;     margin-left: 375px;" alt="">
    <h6 class="Ysabeau" style=" font-size: 1rem;  line-height: 2rem;">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, quibusdam hic accusamus sint mollitia soluta? Sed unde provident explicabo laboriosam numquam repellat itaque doloribus. Accusamus eum necessitatibus consectetur reiciendis aliquid!</h6>
  </v-col>
  </v-row>
  <v-row class="d-flex align-center justify-center mx-16">
    <v-hover>
    <template v-slot:default="{ hover }">
      <div class="overlay-container">
        <v-col class="d-flex align-center justify-center mb-5" max-width="344">
          <v-fade-transition>
            <img
              src="https://swissdelight.qodeinteractive.com/wp-content/uploads/2021/02/h1-iwt-img1.jpg"
              alt=""
              :class="{ 'scaled': hover }"
              width="150px"
            />
          </v-fade-transition>
          <v-fade-transition>
            <v-overlay
              v-if="hover"
              color="#FCF8ED"
              absolute
              class="overlay"
              delay="1000"
            >
             <h6 class="uniqe-size">CHOCOLATE</h6>
            </v-overlay>
          </v-fade-transition>
        </v-col>
      </div>
    </template>
  </v-hover>
    <v-hover>
    <template v-slot:default="{ hover }">
      <div class="overlay-container">
        <v-col class="d-flex align-center justify-center mb-5" max-width="344">
          <v-fade-transition>
            <img
              src="https://swissdelight.qodeinteractive.com/wp-content/uploads/2021/02/h1-iwt-img3.jpg"
              alt=""
              :class="{ 'scaled': hover }"
              width="150px"
            />
          </v-fade-transition>
          <v-fade-transition>
            <v-overlay
              v-if="hover"
              color="#FCF8ED"
              absolute
              class="overlay"
              delay="1000"
            >
             <h6 class="uniqe-size">CHOCO</h6>
            </v-overlay>
          </v-fade-transition>
        </v-col>
      </div>
    </template>
  </v-hover>
    <v-hover>
    <template v-slot:default="{ hover }">
      <div class="overlay-container">
        <v-col class="d-flex align-center justify-center mb-5" max-width="344">
          <v-fade-transition>
            <img
              src="https://swissdelight.qodeinteractive.com/wp-content/uploads/2021/02/h1-iwt-img5.jpg"
              alt=""
              :class="{ 'scaled': hover }"
              width="150px"
            />
          </v-fade-transition>
          <v-fade-transition>
            <v-overlay
              v-if="hover"
              color="#FCF8ED"
              absolute
              class="overlay"
              delay="1000"
            >
             <h6 class="uniqe-size">OREO</h6>
            </v-overlay>
          </v-fade-transition>
        </v-col>
      </div>
    </template>
  </v-hover>
    <v-hover>
    <template v-slot:default="{ hover }">
      <div class="overlay-container">
        <v-col class="d-flex align-center justify-center mb-5" max-width="344">
          <v-fade-transition>
            <img
              src="https://swissdelight.qodeinteractive.com/wp-content/uploads/2021/02/h1-iwt-img4.jpg"
              alt=""
              :class="{ 'scaled': hover }"
              width="150px"
            />
          </v-fade-transition>
          <v-fade-transition>
            <v-overlay
              v-if="hover"
              color="#FCF8ED"
              absolute
              class="overlay"
              delay="1000"
            >
             <h6 class="uniqe-size">CARAMEL</h6>
            </v-overlay>
          </v-fade-transition>
        </v-col>
      </div>
    </template>
  </v-hover>
  </v-row>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="svg-div mt-16">
    <path fill="#FCF8ED" fill-opacity="0.5" d="M0,224L48,208C96,192,192,160,288,133.3C384,107,480,85,576,96C672,107,768,149,864,176C960,203,1056,213,1152,186.7C1248,160,1344,96,1392,64L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
  </svg>

  </div>
  </template>

<script>
export default {
  data: () => ({
    overlay: false
  })
}
</script>

  <style>
  .background-color{
    background-color: #FCF8ED;
    height: 110vh;
  }
  .overlay-container {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .scaled {
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
  }
  .svg-div{
    position: absolute;
      z-index: 1;
      transform: scaleY(-1);
  }
  .svg-div-1{
    position: absolute;
    top: 1200px;
    z-index: 1;
  }
  .svg-div-2{
    transform: scaleY(-1);
    position: absolute;
    z-index: 0;
    margin-bottom: 100px;
  }
  </style>
